﻿import gsap from 'gsap'
import * as THREE from 'three';

// Remove this if you don't need to load any 3D model
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
// import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { MeshSurfaceSampler } from "three/examples/jsm/math/MeshSurfaceSampler.js";
import { mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
window.addEventListener("load", (event) => {
console.log('hi');
if(document.body.classList.contains('home') || document.body.classList.contains('single-products') ) {
// some functions and variables
function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function rgb(r, g, b) {
    return new THREE.Vector3(r, g, b);
}

const stats = new Stats()
//document.body.appendChild(stats.dom)
let  g, ammount, scene, scene2, renderer, renderer2, controls, gu, camera, camera2;
  let gltf1 = [], gltf2 = [];
  var  start = Date.now();
const tLoader = new THREE.TextureLoader();
const circle = tLoader.load('./textures/circle.png');
var hover = false;
// resizeCb = () => onResize()
mousemoveCb = e => onMousemove(e)
 let colors = [
      new THREE.Color(0xffffff),
    //  new Color(0xFF6F61),
    //  new Color(0xC5299B),
     // new Color(0xFEAE51)
    ]

 
 let uniforms = {
      uHover: 0
    }
 scene = new THREE.Scene();

camera = new THREE.PerspectiveCamera(20, innerWidth / innerHeight, 1, 100);
camera.position.set(0, 0, 1).setLength(20);
scene.add(camera);
 renderer2 = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer2.setSize(innerWidth, innerHeight);
renderer2.setPixelRatio(devicePixelRatio);
renderer2.setClearColor( 0x000000, 0 );

document.body.appendChild(renderer2.domElement);

renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer.setSize(innerWidth, innerHeight);
renderer.setPixelRatio(devicePixelRatio);
renderer.setClearColor( 0x000000, 0 );
renderer.domElement.id = 'main-scene';
document.body.appendChild(renderer.domElement);

   

function init() {
  // for mouse in scene 1
    createRaycaster()
    // end for mouse in scene 1
console.clear();
  
  // background code
scene2 = new THREE.Scene();
camera2 = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
   let vCheck = false;

    camera2.position.z = 5;

    var randomisePosition = new THREE.Vector2(1, 2);

    var R = function(x, y, t2) {
        return( Math.floor(192 + 64*Math.cos( (x*x-y*y)/300 + t2 )) );
    }
     
    var G = function(x, y, t2) {
        return( Math.floor(192 + 64*Math.sin( (x*x*Math.cos(t2/4)+y*y*Math.sin(t2/3))/300 ) ) );
    }
      
    var B = function(x, y, t2) {
        return( Math.floor(192 + 64*Math.sin( 5*Math.sin(t2/9) + ((x-100)*(x-100)+(y-100)*(y-100))/1100) ));
    }
    let sNoise = /*glsl*/`
vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec3 permute(vec3 x) { return mod289(((x*34.0)+ 1.0)*x); }

        float snoise(vec2 v) {
            const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                                0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                                -0.577350269189626,  // -1.0 + 2.0 * C.x
                                0.024390243902439); // 1.0 / 41.0
            vec2 i  = floor(v + dot(v, C.yy) );
            vec2 x0 = v -   i + dot(i, C.xx);
            vec2 i1;
            i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
            vec4 x12 = x0.xyxy + C.xxzz;
            x12.xy -= i1;
            i = mod289(i); // Avoid truncation effects in permutation
            vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
                + i.x + vec3(0.0, i1.x, 1.0 ));

            vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
            m = m*m ;
            m = m*m ;
            vec3 x = 2.0 * fract(p * C.www) - 1.0;
            vec3 h = abs(x) - 0.5;
            vec3 ox = floor(x + 0.5);
            vec3 a0 = x - ox;
            m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
            vec3 g;
            g.x  = a0.x  * x0.x  + h.x  * x0.y;
            g.yz = a0.yz * x12.xz + h.yz * x12.yw;
            return 130.0 * dot(m, g);
        }
`;
        let sNoiseVert = /*glsl*/`
 uniform float u_time;
        uniform vec2 u_randomisePosition;

        varying float vDistortion;
        varying float xDistortion;
        varying vec2 vUv;

        void main() {
            vUv = uv;
            vDistortion = snoise(vUv.xx * 3. - u_randomisePosition * 0.15);
            xDistortion = snoise(vUv.yy * 1. - u_randomisePosition * 0.05);
            vec3 pos = position;


            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
`;
  
   let sNoiseFrag;
   if(document.body.classList.contains('home') || document.body.classList.contains('k-lux') || document.body.classList.contains('ultra-lux') || document.body.classList.contains('lux')) {
         sNoiseFrag = /*glsl*/`
vec3 rgb(float r, float g, float b) {
            return vec3(r / 255., g / 255., b / 255.);
        }

        vec3 rgb(float c) {
            return vec3(c / 255., c / 255., c / 255.);
        }

        uniform vec3 u_bg;
        uniform vec3 u_bgMain;
        uniform vec3 u_color1;
        uniform vec3 u_color2;
        uniform float u_time;
uniform vec3 color1;
uniform vec3 color2;
        varying vec2 vUv;
        varying float vDistortion;
uniform float v2;

        void main() {

vec3 bg = rgb(u_bg.r, u_bg.g, u_bg.b);
            vec3 c1 = vec3(0., 0.341, 0.863);
            vec3 c2 = vec3(1., 0.294, 1.);
            vec3 bgMain = rgb(u_bgMain.r, u_bgMain.g, u_bgMain.b);

            float noise1 = snoise(vUv + u_time * 0.08);
            float noise2 = snoise(vUv * 2. + u_time * 0.1);

            vec3 color = bg;
            color = mix(color, color1, noise1 * 0.6);
            color = mix(color, color2, noise2 * .4);

            color = mix(color, mix(c1, color2, vUv.x), vDistortion);

            float border = smoothstep(0.1, 0.6, vUv.x);

            color = mix(color, bgMain, 1. -border);


            gl_FragColor = vec4(color, 1.0);
        }
`;
   }
  
  else if(document.body.classList.contains('duo-lux')) {
            sNoiseFrag = /*glsl*/`
vec3 rgb(float r, float g, float b) {
            return vec3(r / 255., g / 100., b / 255.);
        }

        vec3 rgb(float c) {
            return vec3(c / 255., c / 100., c / 255.);
        }

        uniform vec3 u_bg;
        uniform vec3 u_bgMain;
        uniform vec3 u_color1;
        uniform vec3 u_color2;
        uniform float u_time;
uniform vec3 color1;
uniform vec3 color2;
        varying vec2 vUv;
        varying float vDistortion;
uniform float v2;

        void main() {

vec3 bg = vec3(0.0, 0.0, 0.0);
            vec3 c1 = vec3(0., 0.341, 0.863);
            vec3 c2 = vec3(0., 0.294, 0.);
            vec3 bgMain = vec3(0.1, 0.1, 0.1);

            float noise1 = snoise(vUv + u_time * 0.08);
            float noise2 = snoise(vUv + u_time * 0.03);

            vec3 color = bg;
            color = mix(color, color2, noise1 * 0.6);
          color = mix(color, color1, noise2 * 0.4);

         //   color = mix(color, mix(c1, color2, vUv.x), vDistortion);

            float border = smoothstep(0.1, 0.6, vUv.x);

         //   color = mix(color, bgMain, 1. -border);

     /* color = mix(color, mix( color1, color2, vUv.x), vDistortion);

            float border = smoothstep(0.5, 0.8, vUv.x);

            color = mix(color1, color1, 1. -border); */

            gl_FragColor = vec4(color, 1.0);
}

`;
          
          }
    let geometry2 = new THREE.PlaneGeometry(window.innerWidth / 2, 400, 100, 100);
    let material2 = new THREE.ShaderMaterial({
        uniforms: {
            u_bg: {type: 'v3', value: rgb(0, 0, 0)},
            u_bgMain: {type: 'v3', value: rgb(0, 0, 0)},
            u_color1: {type: 'v3', value: new THREE.Vector3(0., 0.341, 0.863)},
            u_color2: {type: 'v3', value: new THREE.Vector3(1., 0.294, 1.)},
            u_time: {type: 'f', value: 0},
            u_randomisePosition: { type: 'v2', value: randomisePosition },
           color1: {
          type: "v3",
          value: new THREE.Vector3()
        },
         color2: {
          type: "v3",
          value: new THREE.Vector3()
        },
          v2: {
          type: "f",
          value: 0
        } 
        },
        fragmentShader: sNoise + sNoiseFrag,
        vertexShader: sNoise + sNoiseVert,
    });
  if(document.body.classList.contains('home')) {
            material2.uniforms.color1.value = new THREE.Vector3(0, 0.231, 0.463);
        material2.uniforms.color2.value = new THREE.Vector3(0, 0.824, 0.443);
         }
         else if(document.body.classList.contains('k-lux')) {
         material2.uniforms.color1.value = new THREE.Vector3(0,0.341,0.863);
        material2.uniforms.color2.value = new THREE.Vector3(1, 0.294, 1);
         }
         else if(document.body.classList.contains('ultra-lux')) {
         material2.uniforms.color1.value = new THREE.Vector3(1, 0, 0.161);
        material2.uniforms.color2.value = new THREE.Vector3( 0.404, 0, 1);
         }
          else if(document.body.classList.contains('duo-lux')) {
            material2.uniforms.color1.value = new THREE.Vector3(0.952, 0.510, 0.423); 
     material2.uniforms.color2.value = new THREE.Vector3(0.952, 0.510, 0.423);  
            material2.uniforms.v2.value = 1;
         }
        else if(document.body.classList.contains('lux')) {
          material2.uniforms.color1.value = new THREE.Vector3(0, 0.231, 0.463);
        material2.uniforms.color2.value = new THREE.Vector3(0, 0.824, 0.443);
         }
    let mesh2 = new THREE.Mesh(geometry2, material2);
    mesh2.position.set(0, 140, -280);
    mesh2.scale.multiplyScalar(5);
    mesh2.rotationX = -1.0;
    mesh2.rotationY = 0.0;
    mesh2.rotationZ = 0.1;
    scene2.add(mesh2);
    let t2 = 0;
    let j = 0;
    let x = randomInteger(0, 32);
    let y = randomInteger(0, 32);
    const animate = function () {
        requestAnimationFrame( animate );
        mesh2.material.uniforms.u_randomisePosition.value = new THREE.Vector2(j, j);
        mesh2.material.uniforms.u_color1.value = new THREE.Vector3(R(x,y,t2/2), G(x,y,t2/2), B(x,y,t2/2));
        mesh2.material.uniforms.u_time.value = t2;
        if(t2 % 0.1 == 0) {         
            if(vCheck == false) {
                x -= 1;
                if(x <= 0) {
                    vCheck = true;
                }
            } else {
                x += 1;
                if(x >= 32) {
                    vCheck = false;
                }

            }
        }

        // Increase t by a certain value every frame
        j = j + 0.01;
        t2 = t2 + 0.05;
    };
  animate();
// end background code
  
window.addEventListener("resize", (event) => {
    camera.updateProjectionMatrix()
camera2.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer2.setSize(sizes.width, sizes.height)
    renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2))
});

let light = new THREE.DirectionalLight(0xffffff, 1);
light.position.setScalar(1);
scene.add(light, new THREE.AmbientLight(0xffffff, 0.5));
amount = 20000;
 g = new THREE.BufferGeometry();
g.setAttribute("position", new THREE.Float32BufferAttribute(new Array(amount * 3).fill(0), 3));
g.attributes.position.needsUpdate = true;

var mouse = new THREE.Vector2();

window.addEventListener("mousemove", function(event) {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
}, false)
const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( 'three/examples/jsm/libs/draco/' );
loader.setDRACOLoader( dracoLoader );
  
  if (window.location.href.indexOf("weissker.com") > -1) {
 var modelbox =  'https://weissker.com/models/glb/box.glb';

}
 else if (window.location.href.indexOf("localhost") > -1) {
 var modelbox =  '/models/glb/box.glb';

}
  else if (window.location.href.indexOf("weissker.dev") > -1) { 
     var modelbox = 'https://weissker.dev/models/glb/box.glb';
  }
   else if (window.location.href.indexOf("weissker.onemenagerie.com") > -1) { 
     var modelbox = 'https://weissker.onemenagerie.com/models/glb/box.glb';
  }
loader.load(
 modelbox,
	function ( gltf1l ) {
    gltf1 = gltf1l;
		gltf1.animations; // Array<THREE.AnimationClip>
		gltf1.scene; // THREE.Group
		gltf1.scenes; // Array<THREE.Group>
		gltf1.cameras; // Array<THREE.Camera>
		gltf1.asset; // Object
gltf1.scene.scale.set(8, 8, 8);
      gltf1.scene.rotation.y = (Math.PI / 2);
	},
	function ( xhr ) {
	},
	function ( error ) {
	}
);
const loader2 = new GLTFLoader();
const dracoLoader2 = new DRACOLoader();
dracoLoader2.setDecoderPath( 'three/examples/jsm/libs/draco/' );
loader2.setDRACOLoader( dracoLoader2 );
  if (window.location.href.indexOf("weissker.com") > -1) {
 var modelicon =  'https://weissker.com/models/glb/poly3new.glb';

}
 else if (window.location.href.indexOf("localhost") > -1) {
 var modelicon =  '/models/glb/poly3new.glb';

}
  else if (window.location.href.indexOf("weissker.dev") > -1) { 
     var modelicon = 'https://weissker.dev/models/glb/poly3new.glb';
  }
    else if (window.location.href.indexOf("weissker.onemenagerie.com") > -1) { 
     var modelicon = 'https://weissker.onemenagerie.com/models/glb/poly3new.glb';
  }
loader2.load(
	modelicon,
	function ( gltf2l ) {
    gltf2 = gltf2l;
		gltf2.animations; // Array<THREE.AnimationClip>
		gltf2.scene; // THREE.Group
		gltf2.scenes; // Array<THREE.Group>
		gltf2.cameras; // Array<THREE.Camera>
		gltf2.asset; // Object
    gltf2.scene.scale.set(3, 3, 3);
  next()
	},
	function ( xhr ) {
	}
);
} 
init();
function next() {
  
   waitForElement();
  function waitForElement(){
  if(gltf1  && gltf2) {
    waitForElement2();
     }
     else {

        setTimeout(waitForElement, 250);
     }
  }

function waitForElement2() {
    if(gltf1.scene && gltf2.scene ) {
  
  var composer = new EffectComposer(renderer);
composer.addPass(new RenderPass(scene, camera));
gltf1.scene.updateMatrixWorld(true);
let model1 = new THREE.Mesh(mergeModel(gltf1.scene.children[0]));
g.setAttribute("position", pointification(model1, amount));
g.setAttribute("positionStart", pointification(model1, amount));
gltf2.scene.updateMatrixWorld(true);
let model2 = new THREE.Mesh(mergeModel(gltf2.scene.children[0], 1));
    addListeners()
g.setAttribute("positionEnd", pointification(model2, amount));
g.attributes.position.needsUpdate = true;
g.setAttribute("rotDir", new THREE.Float32BufferAttribute(new Array(amount).fill().map(p => Math.random() < 0.5 ? -1: 1), 1));
        const colorIndex = THREE.MathUtils.randInt(0, colors.length - 1)
let pu = {
  morphRatio: {value: 1}
}

let mousey = {
  mouse: { value: new THREE.Vector3(-5.0, 5.0, 0) }
}
let lb = {
  time: { value: 0.0 }
 }
let screen = {
  size: { value: new THREE.Vector3(window.innerWidth, window.innerHeight, 0) }
 }
  let instancedMesh = new THREE.Points(
    g, 
    new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.04,
      side: THREE.DoubleSide,
      map: circle,
      alphaTest: 0.1,
      transparent: true,
      onBeforeCompile: shader => {
        shader.uniforms.morphRatio = pu.morphRatio;
        shader.uniforms.uMouse = mousey.mouse;
        shader.uniforms.tDiffuse = null;
      shader.uniforms.h =  1.0 / 50.0;
         shader.uniforms.uScreen =  screen.size;
      shader.uniforms.ratio = window.innerWidth / window.innerHeight;
      shader.uniforms.opacity = 0;
        shader.uniforms.uPointer = new THREE.Vector3();
        shader.uniforms.uColor = new THREE.Color();
        shader.uniforms.uRotation = 0;
        shader.uniforms.uSize = 0;
        shader.uniforms.time22 = lb.time;
        shader.uniforms.uHover = uniforms.uHover;
        shader.vertexShader = `
          uniform float morphRatio;
          attribute vec3 positionStart;
          attribute vec3 positionEnd;
          attribute float rotDir;
          uniform vec3 uPointer;
          uniform vec3 uColor;
          uniform float uRotation;
          uniform float uSize;
          uniform float uHover;
         varying vec4 vColor2;
          varying float noise;
          uniform float time22;
          varying vec2 vUv;
          uniform vec3 uMouse;
uniform vec3 uScreen;

  vec3 mod289(vec3 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x)
{
  return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

vec3 fade(vec3 t) {
  return t*t*t*(t*(t*6.0-15.0)+10.0);
}

// Classic Perlin noise
float cnoise(vec3 P)
{
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  return 2.2 * n_xyz;
}

// Classic Perlin noise, periodic variant
float pnoise(vec3 P, vec3 rep)
{
  vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
  vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  return 2.2 * n_xyz;
}

float turbulence( vec3 p ) {
    float w = 100.0;
    float t = -.5;
    for (float f = 1.0 ; f <= 10.0 ; f++ ){
      //  float power = pow( 2.0, f );
      //  t += abs( pnoise( vec3( power * p ), vec3( 10.0, 10.0, 10.0 ) ) / power );
    }
    return t;
}


          mat2 rot2d(float a){ return mat2(cos(a), sin(a), -sin(a), cos(a));}
          ${shader.vertexShader}
        `.replace(
          `#include <begin_vertex>`,
          `#include <begin_vertex>
          
           vec3 pStart = positionStart;
            vec3 pEnd = positionEnd;

noise = 10.0 *  -.010 * turbulence( .5 * normal + time22 );
            
            float distRatio = sin(morphRatio * PI);
// float b = 5.0 * cnoise( 0.05 * position + vec3( 0.50 * time22 ) );
 float b = 5.0 * pnoise( 0.05 * position + vec3( 0.50 * time22 ), vec3( 100.0 ) );
            float displacement = - noise + b;

            vec3 pos = mix(pStart, pEnd, morphRatio);
    
 vec3 seg =  pos - uMouse;
        vec3 dir = normalize(seg);
        float dist = length(seg);

        if (dist < 0.5 ) {

vColor2 = vec4(1, 1, 1, 1);
 transformed = pos + normalize(pos) * distRatio * displacement  + (seg * 0.3);

        }

else {

 transformed = pos + normalize(pos) * distRatio * displacement *  (seg * 0.3);

   vColor2 = vec4(1, 1, 1, 1);
}

        vUv = uv;
          `
        );
       shader.fragmentShader = `
varying vec3 vColor;
    uniform vec3 uMouse;
uniform sampler2D tDiffuse;
		uniform float h;
    uniform float ratio;
uniform sampler2D albedo;
		varying vec2 vUv;
varying vec4 vColor2;
       void main() { 
	
// sets colour and transparency - 1 is full white 0.5 would be off white
 vec2 uv = vUv;
      uv = -1. + 2. * uv;
    //  uv -= uMouse;
      uv.x *= ratio;
/// below sets which the mouse area
      if ( length(uv) > 0.15 ) {
       gl_FragColor = vColor2;
}
else {
float r = 0.5;
float g = 0.0;
float b = 0.25;
float a = 1.0;
// gl_FragColor = vec4(r, g, b, a);
 // gl_FragColor = vec4(vColor.x, vColor.y + 0.9 * h, vColor.z, 1);
/* vec4 sum = vec4( 0.0 );

       sum += texture2D( tDiffuse, vec2( vUv.x, vUv.y - 0.1 * h ) );
 

        gl_FragColor = sum;*/
 gl_FragColor = vColor2;
}

    } `;
        g.attributes.position.needsUpdate = true;
      }
    })
  )

scene.add(instancedMesh);
    let height = window.innerHeight / 4;
    let scrollY = 0;
    let endIntro = "no";
    let scrollStart = "no";
    window.addEventListener("scroll", ()=>{
        if (endIntro === "yes") {
            scrollStart = "yes";
            if (scrollY < 6) {
              scrollY = window.scrollY + window.innerHeight;
           scrollY = ((scrollY) / window.innerHeight * 3);
            } else { 
              scrollY = 0; }
        }
    });
    let clock = new THREE.Clock();
    let t = 0;
    renderer.setAnimationLoop(()=>{
        let dt = clock.getDelta();
        t += dt;
        lb.time.value = .00025 * (Date.now() - start);
        if (t <= 2.7) pu.morphRatio.value = Math.sin(t * 0.5) * 0.5 + 0.5;
        else {
            endIntro = "yes";
          document.body.classList.add('scroll');
          document.body.classList.add('scroll');
            if (scrollStart === "yes") {
           if (window.scrollY > (window.innerHeight * 0.7 )) {
             var canvas = document.getElementById("main-scene");
canvas.classList.add("fade-me"); 
           }
             else   if (scrollY < 6) {
                var canvas = document.getElementById("main-scene");
canvas.classList.remove("fade-me"); 
                    if (Math.sin(scrollY * 0.5) * 0.5 + 0.5 > 0.9900042018907306) {
                      pu.morphRatio.value = 0.9900042018907306;
                    }
                    else { pu.morphRatio.value = Math.sin(scrollY * 0.5) * 0.5 + 0.5;
                         }
                }
            }
        }
        renderer2.render(scene2, camera2);

        if (mouseMoved === "yes") {
            mousey.mouse.value.x = mouse.x * 6;
            mousey.mouse.value.y = mouse.y * 6;
            mousey.mouse.value.z = 0;
        }  
        composer.render(scene, camera);
    });
 }
     else {
        setTimeout(waitForElement2, 250);
     }
}
}
  
function mergeModel(model, scale = 1){
  let gs = [];
  model.traverse(child => {
    if(child.isMesh){
      let g = child.geometry.clone();
      for(let a in g.attributes){
        if (a != "position") g.deleteAttribute(a);
      }
      g.applyMatrix4(child.matrixWorld);
    
      gs.push(g);
    }
  })
  return mergeGeometries(gs).center().scale(scale, scale, scale);
}

function pointification(mesh, amount){
  let mss = new MeshSurfaceSampler(mesh).build();
  let pointsData = [];
  let v = new THREE.Vector3();
  for(let i = 0; i < amount; i++){
    mss.sample(v);
    v.toArray(pointsData, i * 3);
  }
  return new THREE.Float32BufferAttribute(pointsData, 3);
}

function createRaycaster() {
    mouse = new THREE.Vector2()
    raycaster = new THREE.Raycaster()
    intersects = []
  }

   function addListeners() {
    window.addEventListener('mousemove', mousemoveCb, { passive: true })
  }

   function removeListeners() {
    window.removeEventListener('mousemove', mousemoveCb, { passive: true })
  }

var mouseMoved = 'no';
   function onMousemove(e) {
    const x = e.clientX / window.innerWidth * 2 - 1
    const y = -(e.clientY / window.innerHeight * 2 - 1)
mouseMoved = 'yes';
    mouse.set(x, y)
   gsap.to(scene.children[3].rotation, {
      x: () => mouse.y * 0.40,
      y: () => mouse.x * 0.40,
      duration: 0.25
    })
    raycaster.setFromCamera(mouse, camera)

    // Check if the ray casted by the `Raycaster` intersects with the model
    intersects = raycaster.intersectObject(scene.children[2])

    if (intersects.length === 0) { // Mouseleave
    
      if (hover) {
       hover = false
        animateHoverUniform(0)
      }
    } else { // Mouseenter
      if (!hover) {
        hover = true
        animateHoverUniform(1)
      }

      // Tween the point to project on the brain mesh
    gsap.to(point, {
        x: () => intersects[0]?.point.x || 0,
        y: () => intersects[0]?.point.y || 0,
        z: () => intersects[0]?.point.z || 0,
        overwrite: true,
        duration: 0.3,
        onUpdate: () => {
          for (let i = 0; i < instancedMesh.count; i++) {
            instancedMesh.setUniformAt('uPointer', i, point)
          }
        }
      })
    }
    
  }

   function animateHoverUniform(value) {
  gsap.to(uniforms, {
      uHover: value,
      duration: 0.25,
      onUpdate: () => {
        for (let i = 0; i < instancedMesh.count; i++) {
          instancedMesh.setUniformAt('uHover', i, uniforms.uHover)
        }
      }
    })
  }


}
});